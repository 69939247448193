.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

body {
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
}


.stock-ticker {
  white-space: nowrap;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
}

.stock-ticker-container {
  animation: marquee 20s linear infinite;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .stock-ticker-container {
    animation: marquee 60s linear infinite;
  }

  .stock-ticker {
    width: auto;
  }
}


.stock-ticker-span {
  padding: 0.5rem;
  background-color: rgba(28, 27, 32, 1);
  border-radius: 10px;
  font-weight: bold;
}


.positive {
  color: green;
}

.negative {
  color: red;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(var(--move-distance));
  }
}